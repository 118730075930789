<template>
  <div class="lotteryResultPage">
    <div id="section-content" class="container container-fluid">
      <div class="bar-back d-flex justify-content-between align-items-center">
        <div id="top"></div>
        <router-link to="/member/lottery" class="mr-auto"><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link>
        <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1" >กติกา</a>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 150px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
            <div class="lotto-title">
                <h4><i class="fas fa-award"></i> ผลรางวัล</h4>
            </div>
        </div>
        <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5" v-if="getResultLotto.reward3_d[0] == '' || getResultLotto.reward3_d[0] == 'xxx' ">
          <h6 v-if="dataInfo">
            <i class="fas fa-crown"></i> {{ dataInfo.group_name }}
            <span class="badge badge-dark" v-if="dataInfo.group_name ">{{ dataInfo.title  }}</span>
          </h6>
          <div class="card border-dark text-center mb-2">
              <div class="card-header text-danger p-1">
                   <span class="flag-icon" v-bind:class="getIconFlag(dataInfo.group_name)"> </span>{{ dataInfo.group_name }}
              </div>
              <div class="card-body p-0">
                  <div class="card text-center w-100 rounded-0 border-0 m-0">
                      <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                          3ตัวบน                                </div>
                      <div class="card-body border-bottom p-0">
                          <h3 class="card-text mb-2">{{ getResultLotto.reward6.substring(this.getResultLotto.reward6.length - 3, this.getResultLotto.reward6.length) || 'xxx' }}</h3>
                      </div>
                  </div>
                  <div class="d-flex flex-row">
                      <div class="card text-center w-50 border-card-right m-0">
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                              2ตัวบน                                    </div>
                          <div class="card-body p-0">
                              <h3 class="card-text mb-2">{{ getResultLotto.reward6.substring(this.getResultLotto.reward6.length - 2, this.getResultLotto.reward6.length) || 'xxx' }}</h3>
                          </div>
                      </div>
                      <div class="card text-center w-50 border-card-right m-0">
                          <div class="card-header text-secondary sub-card-header bg-transparent p-0 border-0">
                              2ตัวล่าง                                    </div>
                          <div class="card-body p-0">
                              <h3 class="card-text mb-2">{{ getResultLotto.reward2_d || xx }}</h3>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

        <div class="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5" v-else>
          <h6 v-if="dataInfo">
            <i class="fas fa-crown"></i> {{ dataInfo.group_name }}
            <span class="badge badge-dark" v-if="dataInfo.group_name ">{{ dataInfo.title  }}</span>
          </h6>
          <div class="card border-dark text-center mb-2">
            <div class="card-header text-danger p-1">รางวัลที่ 1</div>
            <div class="card-body p-1">
              <h3 class="card-text" v-if="getResultLotto">
                {{ getResultLotto.reward6 }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col pr-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">สามตัวหน้า</div>
                <div class="card-body p-1">
                  <h5 class="card-text" v-if="getResultLotto">
                    {{ getResultLotto.reward3_f.filter(Boolean).join(',') || 'xxx, xxx' }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="col px-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">สามตัวหลัง</div>
                <div class="card-body p-1">
                  <h5 class="card-text" v-if="getResultLotto">
                    {{ getResultLotto.reward3_d.filter(Boolean).join(',') || 'xxx, xxx' }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="col pl-1">
              <div class="card border-dark text-center">
                <div class="card-header text-danger p-1">สองตัวล่าง</div>
                <div class="card-body p-1" v-if="getResultLotto">
                  <h5 class="card-text">{{ getResultLotto.reward2_d || xx }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-rule-by-id-modal :advice="dataInfo.advice" />
  </div>
</template>
<script>
import { getFlagIcons } from '@/utils'
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    'app-rule-by-id-modal': ruleByIdModal
  },
  async created () {
    try {
      await this.setLoading(true)
      this.clearResult()
      await this.getInquiryRoundInfomationByID(this.$route.params.id)
      this.dataInfo = this.getInquiryRoundInfomation
      await this.fetchResultLotto(this.$route.params.id)
      this.id = parseInt(this.$route.params.id)
    } catch (error) {
      this.setLoading(false)
    }
    this.setLoading(false)
  },
  data: () => {
    return {
      id: '',
      dataInfo: {}
    }
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getInquiryRoundInfomationByID', 'clearResult']),
    getIconFlag (title) {
      if (title) {
        return getFlagIcons(title)
      } else {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters(['getResultLotto', 'getInquiryRoundInfomation'])
  }
}
</script>

import { render, staticRenderFns } from "./lottoResultPage.vue?vue&type=template&id=5427e934&"
import script from "./lottoResultPage.vue?vue&type=script&lang=js&"
export * from "./lottoResultPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports